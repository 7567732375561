@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700;800&display=swap");

#tsparticles {
	position: absolute;
}

.App {
	background-image: url("../assets/interlaced.png");
	font-family: "Montserrat", sans-serif;
	color: #000;
	font-weight: 700;
}

.title {
	font-family: "Gloria Hallelujah", cursive;
}

.link {
	text-decoration: none;
	position: relative;
	display: block;
}

.link::before {
	content: "";
	display: block;
	position: absolute;
	top: 90%;
	height: 2px;
	width: 100%;
	background-color: #000;
	-webkit-transform-origin: center top;
	transform-origin: center top;
	-webkit-transform: scale(0, 1);
	transform: scale(0, 1);
	transition: color 0.1s, -webkit-transform 0.2s ease-out;
	transition: color 0.1s, transform 0.2s ease-out;
	transition: color 0.1s, transform 0.2s ease-out,
		-webkit-transform 0.2s ease-out;
}

.link:hover::before {
	-webkit-transform-origin: center top;
	transform-origin: center top;
	-webkit-transform: scale(1, 1);
	transform: scale(1, 1);
}
